interface BikramSambatConfig {
    minBSYear: number
    maxBSYear: number

    bsMonthTotalDaysCount: number[][]
    monthReferences: number[][]
    referenceDate: {
        AD: string
        BS: string
    }

    outputSeparator: string
}

const Config: BikramSambatConfig = {
    maxBSYear: 2100,
    minBSYear: 1970,

    outputSeparator: "-",

    bsMonthTotalDaysCount: [
        [30, 31],
        [31, 32],
        [31, 32],
        [31, 32],
        [31, 32],
        [30, 31],
        [29, 30],
        [29, 30],
        [29, 30],
        [29, 30],
        [29, 30],
        [30, 31],
    ],

    monthReferences: [
        [0, 1, 1, 22, 1, 3, 1, 1, 1, 3, 1, 22, 1, 3, 1, 3, 1, 22, 1, 3, 1, 19, 1, 3, 1, 1, 3, 1, 2, 2, 1, 3, 1],
        [
            1,
            2,
            2,
            2,
            2,
            2,
            2,
            1,
            3,
            1,
            3,
            1,
            2,
            2,
            2,
            3,
            2,
            2,
            2,
            1,
            3,
            1,
            3,
            1,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            1,
            3,
            1,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            1,
            3,
            1,
            2,
            2,
            2,
            2,
            2,
            1,
            1,
            1,
            2,
            2,
            2,
            2,
            2,
            1,
            3,
            1,
            1,
            2,
        ],
        [
            0,
            1,
            2,
            1,
            3,
            1,
            3,
            1,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            3,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            1,
            3,
            1,
            3,
            1,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            1,
            3,
            1,
            3,
            1,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            1,
            3,
            1,
            3,
            1,
            1,
            1,
            1,
            2,
            2,
            2,
            2,
            2,
            1,
            3,
            1,
            1,
            2,
        ],
        [
            1,
            2,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            2,
            2,
            2,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            2,
            2,
            2,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            3,
            2,
            2,
            1,
            3,
            1,
            2,
            2,
            2,
            1,
            2,
        ],
        [59, 1, 26, 1, 28, 1, 2, 1, 12],
        [
            0,
            1,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            1,
            3,
            1,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            1,
            3,
            1,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            5,
            1,
            1,
            2,
            2,
            1,
            3,
            1,
            2,
            1,
            2,
        ],
        [0, 12, 1, 3, 1, 3, 1, 5, 1, 11, 1, 3, 1, 3, 1, 18, 1, 3, 1, 3, 1, 18, 1, 3, 1, 3, 1, 27, 1, 2],
        [
            1,
            2,
            2,
            2,
            2,
            1,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            3,
            1,
            3,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            1,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            1,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            1,
            2,
            2,
            2,
            15,
            2,
            4,
        ],
        [
            0,
            1,
            2,
            2,
            2,
            2,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            2,
            2,
            2,
            3,
            2,
            2,
            2,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            1,
            3,
            1,
            3,
            1,
            2,
            2,
            2,
            15,
            2,
            4,
        ],
        [
            1,
            1,
            3,
            1,
            3,
            1,
            14,
            1,
            3,
            1,
            1,
            1,
            3,
            1,
            14,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            18,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            14,
            1,
            3,
            15,
            1,
            2,
            1,
            1,
        ],
        [
            0,
            1,
            1,
            3,
            1,
            3,
            1,
            10,
            1,
            3,
            1,
            3,
            1,
            1,
            1,
            3,
            1,
            3,
            1,
            10,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            14,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            10,
            1,
            20,
            1,
            1,
            1,
        ],
        [
            1,
            2,
            2,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            2,
            2,
            2,
            2,
            2,
            3,
            2,
            2,
            2,
            2,
            2,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            3,
            1,
            2,
            2,
            2,
            2,
            2,
            2,
            2,
            1,
            3,
            1,
            3,
            1,
            20,
            3,
        ],
    ],

    referenceDate: {
        AD: "1913-03-13",
        BS: "1970-01-01",
    },
}

export default Config
